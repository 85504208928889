<template>
  <!-- <transition> -->
    <router-view v-slot="{ Component }">
      <transition>
        <component :is="Component" />
      </transition>
    </router-view>
    <!-- <router-view/>
  </transition> -->
</template>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
  transform: translateX(0);
}
.v-enter,
.v-leave {
  transform: translateX(-100%);
}
.v-leave-to {
  transform: translateX(100%);
}
</style>
