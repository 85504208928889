<template>
  <div class="Home">
    <div class="mvBox">
      <video
        v-if="!flag.value"
        x-webkit-airplay="allow"
        autoplay
        muted
        loop="loop"
        webkit-playsinline="true"
        webkit-airplay="true"
        poster="../assets/videos/home.jpg"
      >
        <source src="../assets/videos/01.mp4" type="video/mp4" />
      </video>
      <div v-else class="phonebgc"></div>
    </div>
    <div class="mengban">
      <div class="titleBox">
        <div class="title">梵溪网</div>
        <div class="title-e">fanxiwang</div>
      </div>
      <div class="midBox">
        <div>欢迎来到我的个人网站</div>
        <div class="text">
          <i>天行健君子以自强不息</i>
          <br />
          <i>地势坤君子以厚德载物</i>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="btton">
          <router-link to="/login">点击进入</router-link>
        </div>
      </div>
    </div>
    <div class="beian">
      <p>Copyright © 2019 梵溪网 www.skydi.cn All Rights Reserved</p>
      <a href="http://www.beian.miit.gov.cn">蜀ICP备19028550号-1&nbsp;</a>
      <p class="mylink">| 联系我们 | 开通时间:2019年9月</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, setup, ref, computed, onMounted } from "vue";
export default defineComponent({
  name: "home",
  setup() {
    const flag = ref(false);
    
    onMounted(() => {
      flag.value = computed(() =>
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i.test(
          navigator.userAgent
        )
      );
    });
    return {
      flag,
    };
  },
});
</script>

<style lang="less" scoped>
.Home {
  //背景图等
  .mvBox {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    width: 100%;
    video {
      position: fixed; //视频定位方式设为固定
      right: 0;
      bottom: 0; //视频位置
      min-width: 100%;
      min-height: 100%; //不会因视频尺寸造成页面需要滚动
      width: auto;
      height: auto; //尺寸保持原视频大小
      z-index: -100; //z轴定位，小于0即可
    }
    .phonebgc {
      display: none;
      width: 100%;
      height: 100vh;
      background-image: url("../assets/videos/home.jpg");
      background-position: center 0;
      background-size: cover;
    }
  }
  @media (max-width: 420px) {
    .mvBox video {
      display: none;
    }
    .mvBox video::-webkit-media-controls-play-button {
      display: none;
    }
    .mvBox .phonebgc {
      display: block;
    }
  }
  //中间面板
  .mengban {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    .titleBox {
      text-align: left;
      margin-top: 30px;
      padding-left: 50px;
      box-sizing: border-box;
      .title {
        font-size: 1.4375rem;
        font-family: "KaiTi";
        letter-spacing: 1px;
        color: #fff;
      }
      .title-e {
        font-size: 1.125rem;
        font-family: "KaiTi";
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #fff;
      }
    }
    .midBox {
      color: #fff;
      position: relative;
      height: 117px;
      top: 30%;
      font-size: 14px;
      background-color: rgba(255, 255, 255, 0.3);
      padding: 10px 0;
      overflow: hidden;
      box-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.4);
      &::after {
        filter: blur(30px);
      }
      &:hover {
        .text {
          transition: 1s;
          transform: rotateX(90deg) translateZ(0px);
        }
        .btton {
          transition: 1s;
          transform: translate(-50%, -50%);
          a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
      .text {
        display: inline-block;
        font-size: 24px;
        text-shadow: 2px 2px 2px #54aefb;
        padding: 10px;
        position: relative;
        width: 300px;
        transform: rotateX(0deg) translateZ(0px);
        cursor: pointer;
        i {
          display: inline-block;
          &:nth-child(1) {
            padding-right: 20px;
          }
          &:nth-child(3) {
            padding-left: 20px;
          }
        }
        span {
          display: inline-block;
          position: absolute;
          background-color: #fff;
          &:nth-child(4) {
            height: 41px;
            width: 2px;
            left: 0;
            top: 0;
            transform: translateY(-8px);
          }
          &:nth-child(5) {
            height: 2px;
            width: 41px;
            left: 0;
            top: 0;
            transform: translateX(-8px);
          }
          &:nth-child(6) {
            height: 41px;
            width: 2px;
            right: 0;
            bottom: 0;
            transform: translateY(8px);
          }
          &:nth-child(7) {
            height: 2px;
            width: 41px;
            right: 0;
            bottom: 0;
            transform: translateX(8px);
          }
        }
      }
      .btton {
        position: absolute;
        display: inline-block;
        border: 1px solid #fff;
        font-weight: 700;
        line-height: 30px;
        height: 30px;
        width: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -350%);
        cursor: pointer;
      }
    }
  }
  //备案号
  .beian {
    position: absolute;
    bottom: 20px;
    font-size: 12px;
    color: #9c9c9c;
    text-align: center;
    width: 100%;
    a {
      color: #9c9c9c;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-user-select: none;
      -moz-user-focus: none;
      -moz-user-select: none;
      text-decoration: none;
    }
    .mylink {
      display: inline-block;
    }
  }
}
</style>
